.container {
  display: flex;
  align-items: center;
}

.checkbox {
  min-height: 32px;
  min-width: 32px;
  border-radius: 4px;
  border: 1px solid #7f8c8d;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkmark {
  color: #7f8c8d;
}

.label {
  margin-left: 16px;
}
