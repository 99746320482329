.overlay {
  position: fixed;
  inset: 0;
  z-index: 5;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  padding: 48px;
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.closeIcon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.info {
  text-align: center;
  margin: 32px 0;
}

.link {
  margin-bottom: 32px;
}
