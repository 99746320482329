.titleContainer {
  display: flex;
  align-items: center;
}

.title {
  flex: 1;
  font-size: 24px;
  margin: 0 16px;
}

.backIcon {
  width: 24px;
  height: 24px;
}

.input {
  width: 100%;
  font-size: 32px;
}

.toolbar {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.submitButton {
  margin-right: 8px;
}

.swishButton {
  display: none;
  border: 2px solid #27ae60;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
}

.swishButton img {
  width: 80px;
  height: 24px;
}

@media (max-width: 900px) {
  .title {
    font-size: 16px;
  }

  .swishButton {
    display: flex;
  }
}
