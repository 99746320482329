.marker {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.marker:hover {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
}

.pulsating {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  animation: pulse 2s infinite;
}

.small {
  height: 16px;
  width: 16px;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
  }

  70% {
    box-shadow: 0 0 0 16px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
