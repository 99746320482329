.label {
  margin-bottom: 8px;
}

.input {
  border: 1px solid #7f8c8d;
  padding: 8px;
  border-radius: 4px;
  box-sizing: border-box;
}

.textarea {
  min-height: 80px;
}
