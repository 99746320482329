.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -8px;
}

.item {
  margin: 8px;
}
