.container {
  width: 100%;
  height: 48px;
  border-radius: 26px; /* Half height + 2px */
  background: #ecf0f1;
  overflow: hidden;
  border: 2px solid #27ae60;
}

.indicator {
  height: 100%;
  background: #27ae60;
  border-top-right-radius: 26px;
  border-bottom-right-radius: 26px;
  position: relative;
}

.label {
  position: absolute;
  line-height: 48px;
  font-weight: 400;
  padding: 0 16px;
}
