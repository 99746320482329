.outerContainer {
  position: relative;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.innerContainer {
  overflow: scroll;
  margin: 0 auto;
  max-width: 1000px;
}

.outerContent {
  display: flex;
  padding: 32px;
}

.innerContent {
  flex: 3;
}

.details {
  display: flex;
  flex-wrap: wrap;
  margin: 16px -4px 0 -4px;
}

.actionButtons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 20px -8px 8px;
}

.bottomFade {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 32px;
  background: linear-gradient(rgba(255, 255, 255, 0), white);
}

@media (max-width: 900px) {
  .outerContent {
    flex-direction: column;
    padding: 16px 16px 32px 16px;
  }
}
