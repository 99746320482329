.container {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  color: #000;
}

.container:hover {
  background: rgba(0, 0, 0, 0.05);
}

.active {
  font-weight: 400;
}

.indicator {
  min-height: 16px;
  min-width: 16px;
  border-radius: 2px;
  margin-right: 8px;
}
