.container {
  flex: 2;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  border: 2px dashed #27ae60;
  padding: 16px;
  border-radius: 8px;
}

.amount {
  font-weight: 400;
  font-size: 32px;
  text-align: center;
}

.label {
  margin-top: 8px;
  margin-bottom: 16px;
  text-align: center;
}

.invoice {
  margin-top: 16px;
  text-align: center;
}

@media (max-width: 900px) {
  .container {
    margin-left: 0;
    margin-top: 24px;
  }
}
