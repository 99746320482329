.outerContainer {
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.innerContainer {
  position: relative;
}

.content {
  overflow: scroll;
  padding: 16px;
}

.title {
  font-weight: 500;
  margin: 16px;
}

.segment {
  margin: 16px;
}

.markerInfo {
  margin: 16px;
}

.item {
  margin: 16px;
  display: flex;
  flex-direction: column;
}

.terms {
  margin: 16px;
}

.bottomFade {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 32px;
  background: linear-gradient(rgba(255, 255, 255, 0), white);
}

.toolbar {
  display: flex;
  margin: 8px;
}

.button {
  margin: 8px;
}

@media (max-width: 900px) {
  .content {
    padding: 0 0 16px 0;
  }
}
