.container {
  margin-top: 16px;
}

.title {
  margin-bottom: 8px;
  font-weight: 500;
}

.textarea {
  width: 100%;
}

.toolbar {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.submitButton {
  margin-right: 8px;
}
