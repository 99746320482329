.container {
  display: flex;
}

.row {
  flex-direction: row;
  align-items: center;
  margin: -8px;
}

.column {
  flex-direction: column;
  align-items: center;
  margin: 32px 0;
}

.link {
  padding: 16px;
  border-radius: 8px;
  margin: 8px;
  text-decoration: none;
  white-space: nowrap;
  border: 2px solid #27ae60;
}

.link:hover {
  opacity: 0.8;
}

.activeLink {
  background: #27ae60;
  color: #fff;
}
