.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  height: 100%;
}

.section {
  margin: 16px 0;
}

.logo {
  width: 240px;
}

.slogan {
  margin-top: 16px;
  font-size: 20px;
  font-weight: 700;
}

.name {
  font-size: 32px;
  font-weight: 700;
}

.description {
  margin-bottom: 8px;
  font-weight: 700;
}

.header {
  margin-bottom: 8px;
  font-weight: 700;
}

.support {
  font-weight: 700;
  margin-bottom: 16px;
}
