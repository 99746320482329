.container {
  position: relative;
  height: 100%;
  width: 320px;
  background: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  overflow: scroll;
  z-index: 3;
}

.content {
  margin: 8px;
}

.empty {
  padding: 8px;
  color: #7f8c8d;
}

.header {
  padding: 8px;
  font-weight: 700;
  font-size: 12px;
}

@media (max-width: 700px) {
  .container {
    display: none;
  }
}
