.container {
  display: flex;
  align-items: center;
  margin: 4px;
  padding: 4px;
  cursor: pointer;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 16px;
  min-width: 16px;
  border-radius: 2px;
  margin-right: 8px;
  color: #fff;
  font-size: 10px;
}
