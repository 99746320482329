.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slogan {
  margin: 0 16px;
  text-align: center;
  font-weight: 500;
}
