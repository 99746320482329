.myLocation {
  /* Same as Google Maps buttons. */
  border: 0;
  margin: 8px;
  padding: 12px;
  border-radius: 2px;
  background: #fff;
  color: #666;
  cursor: pointer;
  user-select: none;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
}

.myLocation:hover {
  color: #000;
}

.pinImage {
  margin-right: 4px;
}
