.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  background: #27ae60;
  color: #fff;
  padding: 16px 8px;
  border-radius: 8px;
  cursor: pointer;
}

.container:hover {
  background: #229955;
}

.logo {
  width: 40px;
  margin-right: 16px;
  background: #fff;
  padding: 8px;
  border-radius: 4px;
}
