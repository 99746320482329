body,
textarea,
input,
select {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 16px;
}

b {
  font-weight: 700;
}

a {
  color: #27ae60;
}

/* Hack so Root component can be 100% height. */
html,
body,
#root {
  height: 100%;
}
