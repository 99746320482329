.outerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../../images/bg.jpg);
  background-size: 600px;
  background-position: center;
  min-height: 100%;
}

.innerContainer {
  position: relative;
  max-width: 900px;
  background-color: #fff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}

.hero {
  display: flex;
  align-items: center;
  height: 400px;
  background-image: url(../../images/kids/kids.jpg);
  background-size: cover;
  font-weight: 700;
  font-size: 48px;
  line-height: 1.3;
  text-align: center;
  text-shadow: 0 0 16px rgb(0 0 0 / 40%);
  color: #fff;
  padding: 32px;
}

.logo {
  width: 240px;
  align-self: center;
  position: absolute;
  top: 464px; /* 400px + 32px * 2 padding */
  left: 50%;
  margin-top: -49px;
  margin-left: -120px;
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  padding: 32px;
}

.link {
  position: sticky;
  bottom: 32px;
  font-size: 32px;
  font-weight: 700;
  margin: 32px 0;
  background-color: #27ae60;
  color: #fff !important;
  align-self: center;
  padding: 16px 24px;
  border-radius: 8px;
}

.link:hover {
  text-decoration: none !important;
  transform: scale(1.1);
  transition: all 0.5s;
}

.content p {
  margin-block-start: 12px;
  margin-block-end: 12px;
  margin-inline-start: 0;
  margin-inline-end: 0;
  line-height: 1.5;
}

.content a {
  font-weight: 700;
  text-decoration: none;
  color: #000;
}

.content a:hover {
  text-decoration: underline;
}

.listContainer {
  display: flex;
  align-items: flex-start;
}

.list {
  counter-reset: my-counter;
  list-style: none;
  padding-left: 56px;
  line-height: 1.5;
}
.list li {
  counter-increment: my-counter;
  position: relative;
  margin-bottom: 16px;
}

.list li::before {
  content: counter(my-counter);
  position: absolute;
  top: 0;
  left: -56px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #27ae60;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.listImage {
  width: 300px;
  margin-left: 32px;
}

.listImageSquare {
  display: none;
}

.about {
  font-size: 14px;
  line-height: 1.5;
  color: #666;
}

@media (max-width: 700px) {
  .listContainer {
    flex-direction: column;
  }

  .listImage {
    display: none;
  }

  .listImageSquare {
    display: block;
    width: 100%;
  }
}

@media (max-width: 900px) and (min-width: 701px) {
  .outerContainer {
    background-image: none;
  }

  .hero {
    font-size: 32px;
    padding: 16px;
  }

  .logo {
    top: 432px; /* 400px + 16px * 2 */
  }

  .content {
    box-shadow: none;
    padding: 16px;
  }
}
