.container {
  background-color: #ecf0f1;
  border-radius: 8px;
  padding: 8px;
  margin: 4px;
}

.label {
  font-weight: 400;
  margin-bottom: 4px;
}
