.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -16px 32px -16px;
}

.item {
  padding: 8px;
  margin: 8px;
  border-radius: 4px;
  background: #eee;
  text-decoration: none;
  color: #666;
}

.activeItem {
  font-weight: 400;
}

@media (max-width: 700px) {
  .item {
    padding: 4px 8px;
    margin: 4px;
  }
}
