.container {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
}

.item {
  border: 1px solid #000;
  border-radius: 8px;
  padding: 16px;
  margin: 8px;
}

.header {
  font-weight: 700;
  margin-bottom: 8px;
}
