.label {
  margin-bottom: 8px;
}

.segment {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #7f8c8d;
  border-radius: 8px;
  overflow: hidden;
}

.item {
  padding: 8px;
  cursor: pointer;
  text-align: center;
}

.separator {
  background: #7f8c8d;
  width: 1px;
  align-self: stretch;
}

.selected {
  background: #7f8c8d;
  color: #fff;
}
