.container {
  display: flex;
  align-items: center;
}

.slogan {
  flex: 1;
  text-align: center;
  margin: 0 8px;
  font-weight: 500;
}

.openIcon,
.closeIcon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.overlay {
  z-index: 5;
  position: fixed;
  inset: 0;
  background: #fff;
}

.modal {
  background: #fff;
  margin: 16px;
}

.modal:focus {
  outline: none;
}

.header {
  display: flex;
  justify-content: flex-end;
}
