.container {
  display: flex;
  align-items: center;
  margin: 0 -16px;
}

.item {
  font-weight: 700;
  text-decoration: underline;
  margin: 16px;
}
