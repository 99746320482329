.container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.item {
  margin: 16px;
  padding: 16px;
  background: #eee;
  color: #333;
  border-radius: 4px;
  text-decoration: none;
  width: 360px;
  min-height: 122px; /* To make all boxes same height even though some only have one line of description. */
}

.title {
  font-size: 20px;
  font-weight: 400;
}

.description {
  margin-top: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.readMore {
  display: inline-flex;
  background: #27ae60;
  color: #fff;
  padding: 4px 8px;
  margin-top: 4px;
  border-radius: 4px;
}
