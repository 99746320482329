.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.row {
  flex: 1;
  display: flex;
  height: 100%;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.map {
  flex: 1;
  position: relative;
}
